import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <div className="header">
      <div className="typewriter-animation">
        <h1>will kung</h1>
        <h1>full stack developer</h1>
        <h1>react specialist, ai enthusiast</h1>
      </div>
      <div class="scroll-down"></div>
    </div>
  );
};

export default Header;
