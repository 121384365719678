import React from 'react';
import Header from '../components/Header/Header';
import ArticleLinks from '../components/ArticleLinks/ArticleLinks';

const FrontPage = () => {
  return (
    <div className="page">
      <Header />
      <ArticleLinks />
    </div>
  );
};

export default FrontPage;
