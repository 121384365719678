export const kdanData = {
  title: 'KDANMobile Internship',
  service: 'Chatbot Development',
  timeline: 'Jun 2024 - Aug 2024 (3 months)',
  role: 'Software Engineer Intern',
  description: `At KDANMobile, I developed a chatbot for a client, aimed at streamlining data inquiries such as daily revenue and stock levels. This role involved front-end and back-end development, ensuring the chatbot was both functional and user-friendly.`,
  images: [
    {
      src: './companies/kdan/kdan-ai.jpg',
      alt: 'company branch'
    }
  ],
  sections: [
    // {
    //   headerType: 'h3',
    //   title: 'FRONTEND DEVELOPMENT',
    //   contentType: 'list',
    //   content: [
    //     'Documented TalkBack accessibility guidelines for internal teams',
    //     'Contributed to creating a new onboarding experience for all designers',
    //     'Created a component from beginning to end for usage in all products',
    //     'Volunteered with Suit Up to empower youth',
    //     'Updated module libraries for various products'
    //   ],
    //   images: []
    // },
    {
      headerType: 'h3',
      title: 'FRONTEND DEVELOPMENT',
      contentType: 'paragraph',
      content:
        "Designed and implemented the chatbot's front-end using React JavaScript. The interface allows users to interact with data through visualizations such as graphs and tables, enabling analysis and decision-making",
      images: []
    },
    {
      headerType: 'h3',
      title: 'FIGMA DESIGN',
      contentType: 'paragraph',
      content: 'The design I followed but added my own twist to.',
      images: [
        {
          src: './companies/kdan/figma.jpg',
          alt: 'company branch'
        }
      ]
    },
    {
      headerType: 'h3',
      title: 'ASK A QUESTION',
      contentType: 'demonstration',
      position: 'left',
      content: `Ask any question regarding data relevant to the client company.`,
      images: [
        {
          src: './companies/kdan/opening-chatbot.gif',
          alt: 'preview of opening chatbot'
        }
      ]
    },
    {
      headerType: 'h3',
      title: 'LOOK AT THE TABLE OF DATA',
      contentType: 'demonstration',
      position: 'right',
      content: `Review the data relevant to your inquiry.`,
      images: [
        {
          src: './companies/kdan/opening-chatbot.gif',
          alt: 'preview of opening chatbot'
        }
      ]
    },
    {
      headerType: 'h3',
      title: 'GRAPH THE DATA',
      contentType: 'demonstration',
      position: 'left',
      content: `Choose the graph type and variable axis.`,
      images: [
        {
          src: './companies/kdan/opening-chatbot.gif',
          alt: 'preview of opening chatbot'
        }
      ]
    },
    {
      headerType: 'h3',
      title: 'BACKEND',
      contentType: 'paragraph',
      content: `
          I began by conducting an initial audit around current use cases for stars across all products and experiences. 
          This allowed me to understand current use cases and consider potential use cases in the future. Additionally, 
          I had to consider both mobile and web experiences, and an audit helped me see the context and placement of 
          this particular component. This internal audit examined over 20 unique use cases across 6 distinct products.
        `,
      images: [
        {
          src: './companies/kdan/chatbot_layout.jpg',
          alt: 'chatbot_layout'
        }
      ]
    },
    {
      headerType: 'h3',
      title: 'INTEGRATION AND TESTING',
      contentType: 'paragraph',
      content: `
          This audit revealed inconsistencies with aspects like size, color, shape, fill, and spacing. With color, 
          there were also accessibility concerns as yellow stars do not pass web accessibility guidelines (WCAG). 
          As an accessibility-focused designer, I realized the color contrast was a concern as the stars with shaded 
          inactive states meant our ratings relied on color alone to distinguish the states, which is extremely inaccessible.
        `,
      images: [
        {
          src: './companies/kdan/langfuse.jpg',
          alt: 'langfuse'
        }
      ]
    },
    {
      headerType: 'h3',
      title: 'DEPLOYMENT',
      contentType: 'paragraph',
      content: ``,
      images: [
        {
          src: './companies/kdan/deployment.jpg',
          alt: 'langfuse'
        }
      ]
    },
    {
      headerType: 'h3',
      title: 'MOMENTS',
      contentType: 'paragraph',
      content:
        'Just highlighting some moments during my internship at KDANMobile.',
      images: [
        { src: './companies/kdan/hi_team.jpg', alt: 'Hi Team' },
        { src: './companies/kdan/aws.jpg', alt: 'AWS Summit' }
      ]
    }
  ]
};
